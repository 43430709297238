import React from 'react';
import { Typography } from '@mui/material';

const LearnPaymentPlanSubHeaderText = (props) => {
  const {
    existingPaymentPlan,
    invoiceDetails,
  } = props
  const hasExistingPaymentPlan = existingPaymentPlan && existingPaymentPlan.length && existingPaymentPlan.find((p) => !p.isFullyPaid)
  const invoiceIsPaid = invoiceDetails && invoiceDetails.status === 'paid'
  const hasInvoiceLoaded = Boolean(invoiceDetails)
  let text;

  if (hasExistingPaymentPlan) {
    text = 'There is an existing payment plan. Only one payment plan can be active at a time.'
  } else if (invoiceIsPaid) {
    text = 'This invoice is fully paid. No payment plan options available.'
  } else if (hasInvoiceLoaded) {
    if ((invoiceDetails.total ? invoiceDetails.total / 100 : 0) < 300) {
      text = 'Minimum outstanding balance for a payment plan is $300.'
    } else {
      text = `Current Invoice Total: $${(invoiceDetails.total ? invoiceDetails.total / 100 : 0).toFixed(2)}`
    }
  } else {
    return <></>
  }
  return (
    <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
      {text}
    </Typography>
  )
}

export default LearnPaymentPlanSubHeaderText
