import React, { useState, useEffect } from 'react'
import { Paper, Divider, Button, Grid, Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getBots, activateBotById, getRunningBots, activateBotScheduleById } from '../../store/bots'
import BotLine from './BotLine'
import isLoggedIn from '../../utils/isLoggedIn';
import { store } from "../../store"
import { removeUser, resetStore } from "../../store/authentication"
import { Toast } from '../'

const BotSummaryPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate({ replace: true });
  const authentication = useSelector(state => state.authentication);
  const bots = useSelector(state => state.bots.bots);
  const [daemon, setDaemon] = React.useState(false);
  const [intervals, setIntervals] = React.useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [openFailedToast, setOpenFailedToast] = useState(false);

  useEffect(async () => {
    if (!await isLoggedIn(authentication)) {
      navigate('/login')
    }

    try {
      const {
        company: {
          companyId,
        }
      } = authentication

      if (!daemon) {
        const intervalId = setInterval(async () => {
          await dispatch(getBots(companyId))
        }, 60000); // 60000 milliseconds = 1 minute
        setIntervals([...intervals, intervalId])
        setDaemon(true)
      }

      if (!bots || !bots.length) {
        dispatch(getBots(companyId))
      }
    } catch (e) {

    }
  }, [bots])

  const handleSignoutClick = async () => {
    try {
      intervals.forEach((intervalId) => clearInterval(intervalId))
    } catch (e) {

    }
    store.dispatch(removeUser())
    store.dispatch(resetStore())
    navigate('/login')
  }

  const activateBot = async (companyId, relatedBot) => {
    console.log("relatedBot function: ", relatedBot)
    let hasFailedStart = false
    if (relatedBot.isEnabled) {
      const res = await dispatch(activateBotById(companyId))
      hasFailedStart = res  && res.botRuns.length && !res.botRuns.body 
        ? res.botRuns.find(x => x.exception)
        : true
    } else {
      const res = await dispatch(activateBotScheduleById(companyId))
      await dispatch(getBots(companyId))
    }
    if (!hasFailedStart) {
      setOpenToast(true)
    } else {
      setOpenFailedToast(true)
    }
  }

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
    setOpenFailedToast(false);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Toast 
        open={openToast} 
        onClose={handleToastClose} 
        message={'Successfully started bot.'} 
        severity={'success'}
      />
      <Toast 
        open={openFailedToast} 
        onClose={handleToastClose} 
        message={'Bot already running'} 
        severity={'error'}
      />

      <Paper elevation={3} style={{ padding: '20px', maxWidth: '500px', margin: 'auto', minWidth: '400px' }}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/async_lambda_logo.png`}
            alt="Example"
            sx={{
              maxWidth: '300px',
              height: 'auto',
              display: 'block',
              margin: '0 auto',
            }}
          />
          <Grid container spacing={2} justifyContent="space-between" style={{ marginTop: '10px' }}>
            {bots && bots.length 
              ? bots.map((bot, index) => <BotLine bot={bot} index={index} activateBot={activateBot} />) : []}
          </Grid>
          <Divider style={{ width: '100%', margin: '10px', backgroundColor: 'black' }} />
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Button onClick={handleSignoutClick} variant="contained" style={{ margin: '10px', backgroundColor: '#d4967d', fontWeight: 'bold' }}>Sign Out</Button>
          </Box>
      </Paper>
    </Grid>
  );
}

export default BotSummaryPage
