import React, { useState } from 'react';

import {
  TableCell, TableRow, IconButton, Collapse,
  Box, TextField, Button,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

function formatAmountasDecimal(amount) {
  if (!amount || !Number(amount)) return '$0'
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount/100);
}

function formatTotalPaid(payments) {
  const total = payments.reduce((acc, curr) => acc + curr.amountPaid, 0)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(total/100);
}

function remainingOwed(amount, payments) {
  const totalPaid = payments.reduce((acc, curr) => acc + curr.amountPaid, 0)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format((amount - totalPaid)/100);
}

function formatIsActive(row) {
  const totalPaid = row.paymentsMade ? row.paymentsMade.reduce((acc, curr) => acc + curr.amountPaid, 0 ) : 0
  const paymentPlanTotal = row.paymentPlanTotal
  return (paymentPlanTotal - totalPaid > 0) ? 'True' : 'False'
}

function formatIsPasDue(row) {
  return (row.invoicesSent - row.paymentsMade > 1) ? 'True' : 'False'
}

export default function ExpandableTableRow({ row, onSave }) {
  const [open, setOpen] = useState(false);
  const [rowUpdates, setRowUpdates] = useState({
    totalAmount: formatAmountasDecimal(row.paymentPlanTotal),
    monthlyPercent: row.selectedPlanDetails.percent,
    totalMonths: row.selectedPlanDetails.maxMonths,
  });

  const [errors, setErrors] = useState({
    totalAmount: '',
    monthlyPercent: '',
    totalMonths: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRowUpdates({ ...rowUpdates, [name]: value || '' });
    setErrors({ ...errors, [name]: '' });
  };
  

  const handleSave = () => {
    let hasErrors = false
    const newErrors = { ...errors }
    if (Number(rowUpdates.monthlyPercent) * Number(rowUpdates.totalMonths) != 100) {
      newErrors.monthlyPercent = 'Total Months * Monthly Percent must equal 100'
      newErrors.totalMonths = 'Total Months * Monthly Percent must equal 100'
      hasErrors = true
    }
    const newAmountAsNumber = Number(rowUpdates.totalAmount.replace("$", "")) * 100
    const totalPaid = row.paymentsMade.reduce((acc, curr) => acc + curr.amountPaid, 0)
    
    if (newAmountAsNumber < totalPaid) {
      newErrors.totalAmount = 'Payment plan total must be greater then already paid balance'
      hasErrors = true
    } 
    if (hasErrors) {
      setErrors(newErrors)
      return
    }

    onSave(row, rowUpdates);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.clientId}</TableCell>
        <TableCell>{formatAmountasDecimal(row.paymentPlanTotal)}</TableCell>
        <TableCell>{formatTotalPaid(row.paymentsMade)}</TableCell>
        <TableCell>{remainingOwed(row.paymentPlanTotal, row.paymentsMade)}</TableCell>
        <TableCell>{formatIsActive(row)}</TableCell>
        <TableCell>{formatIsPasDue(row)}</TableCell>
        <TableCell>{row.createdAt}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <TextField
                margin="normal"
                label="Total Payment Plan Amount"
                variant="outlined"
                required
                name="totalAmount"
                value={rowUpdates.totalAmount}
                onChange={handleChange}
                helperText={errors.totalAmount}
                error={Boolean(errors.totalAmount)}
              />
              <TextField
                margin="normal"
                label="Monthly Percent"
                variant="outlined"
                required
                name="monthlyPercent"
                value={rowUpdates.monthlyPercent}
                onChange={handleChange}
                helperText={errors.monthlyPercent}
                error={Boolean(errors.monthlyPercent)}
              />
              <TextField
                margin="normal"
                label="Total Months"
                variant="outlined"
                required
                name="totalMonths"
                value={rowUpdates.totalMonths}
                onChange={handleChange}
                helperText={errors.totalMonths}
                error={Boolean(errors.totalMonths)}
              />
              <div></div>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
