import React, { useState, useEffect } from 'react'
import { Paper, Divider, Button, Typography, Grid, Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getBots, activateBotById, getRunningBots } from '../../store/bots'
import isLoggedIn from '../../utils/isLoggedIn';

const BotLine = (props = {}) => {
  const bots = useSelector(state => state.bots.bots);
  const {
    bot,
    index,
    activateBot,
  } = props
  console.log("Bot: ", bot)
  const relatedBot = bots.find((b) => b.botId == bot.botId) || {}
  console.log("relatedBot: ", relatedBot)
  const hasActiveRuns = relatedBot.activeBotRuns && relatedBot.activeBotRuns.length
  let buttonText = hasActiveRuns 
    ? 'Running' 
    : 'Activate'
  if (!relatedBot.isEnabled) {
    buttonText = 'Enable Daily Runs'
  }
  let status = relatedBot.isEnabled ? 'Active' : 'Descheduled'
  return (
    <React.Fragment key={`bot-line-${bot.botId}`}>
      <Grid container item justifyContent="space-between" alignItems="center">
        <Typography style={{fontWeight: 'bold'}}>{bot.botName}</Typography>
        <Button variant="contained" 
          style={{ backgroundColor: '#d4967d', fontWeight: '600' }} 
          onClick={() => activateBot(bot.botId, relatedBot)}
        >
          {buttonText}
        </Button>
      </Grid>
      {index < bots.length - 1 
        && <Divider style={{ width: '100%', margin: '5px 0', backgroundColor: 'black' }} />}
    </React.Fragment>
  )
}

export default BotLine
