import React, { useState, useEffect } from 'react';

import {
  TextField,
} from '@mui/material';

import { post } from '../../../store/utils'
export default function PaymentPlanSearchBar(props) {
  const {
    page,
    setRows,
  } = props
  const [search, setSearch] = useState('');
  useEffect(() => {
    const makeApiCall = async () => {
      try {
        const res = await post(``, { searchTerm: search, page },
        { customURL: `https://5pvfpf93rd.execute-api.us-east-1.amazonaws.com//api/learn/paymentplans/search`})
        setRows(res.paymentPlans);
      } catch (e) {
        console.error('Error fetching data:', e);
      }
    }
 
     const triggerCall = setTimeout(() => {
       makeApiCall()
     }, 500)
 
     return () => clearTimeout(triggerCall)
   }, [search, page])

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={handleSearchChange}
        sx={{ backgroundColor: 'white' }}
      />
    </>
  );
}
