import { BOTS } from './constants'
import { post } from './utils'
const { SET_BOTS, SET_SINGLE_BOT, SET_BOT_ACTIVATION } = BOTS;

const setBots = (bots) => ({ type: SET_BOTS, bots })
const setBotActivation = (activatedBots) => ({ type: SET_BOT_ACTIVATION, activatedBots })

export const getRunningBots = (companyId) => async dispatch => {
  const res = await post('/api/bots/running', { companyId })
  dispatch(setBotActivation(res.recentBotRuns))
}

export const activateBotById = (botId) => async dispatch => {
  const res = await post('/api/bots/activate', { botId })
  dispatch(setBotActivation(res))
  return res
}

export const activateBotScheduleById = (botId) => async dispatch => {
  const res = await post('/api/bots/activateschedule', { botId })
  dispatch(setBotActivation(res))
  return res
}

export const getBots = (companyId) => async dispatch => {
  const res = await post('/api/bots/list', { companyId })
  dispatch(setBots(res.bots))
}

const DEFAULT_STATE = {
  bots: [],
  currentBot: {},
  activatedbots: [],
}

export default function reducer(state=DEFAULT_STATE, action) {
    let newState;
    switch (action.type) {
      case SET_BOTS:
        newState = {...state}
        newState.bots = action.bots
        return newState
      case SET_SINGLE_BOT:
        newState = {...state}
        newState.currentBot = action.bot
        return newState
      case SET_BOT_ACTIVATION:
        newState = {...state}
        const {
          botId,
          botRuns,
        } = action.activatedBots
        const updatedBots = []
        for (const bot of newState.bots) {
          const newBot = {...bot}
          if (newBot.botId == botId) {
            newBot.activeBotRuns = botRuns
          }
          updatedBots.push(newBot)
        }
        newState.bots = updatedBots
        return newState
      default:
        return state;
    }
}
