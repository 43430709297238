import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Paper, Container, Typography, TextField, FormHelperText, Grid,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Box } from '@mui/material';
import { submitFormData } from '../../store/forms'
import { useDispatch } from 'react-redux'
import { Toast } from '..'
import Popup from './PopUpAddress'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


const Form = () => {
  const dispatch = useDispatch()
  const { formId } = useParams();
  const query = useQuery();
  const queryParamEmail = query.get('email');

  const [openToast, setOpenToast] = useState(false);
  const [openFailedToast, setOpenFailedToast] = useState(false);


  const [formData, setFormData] = useState({
    bestEmail: '',
    emailOnFile: queryParamEmail || 'example@example.com',
    autoCharge: '',
  });

  const [errors, setErrors] = useState({
    bestEmail: '',
    emailOnFile: '',
    autoCharge: '',
  });

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
    setOpenFailedToast(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value || '' });
    setErrors({ ...errors, [name]: '' });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newErrors = {};

    if (formData.bestEmail.trim() === '') {
      newErrors.bestEmail = 'Email is required.';
    } else if (!validateEmail(formData.bestEmail.trim())) {
      newErrors.bestEmail = 'Please provide a valid email.';
    }

    if (formData.emailOnFile.trim() === '') {
      newErrors.emailOnFile = 'Email is required.';
    } else if (!validateEmail(formData.emailOnFile.trim())) {
      newErrors.emailOnFile = 'Please provide a valid email.';
    }

    if (!formData.autoCharge) {
      newErrors.autoCharge = 'Selection required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const formPostSuccess = await dispatch(submitFormData({
        formId,
        formData,
        companyId: 'LEARN'
      }))
      if (formPostSuccess) {
        setOpenToast(true);
      } else {
        openFailedToast(true)
      }
    }
  };
  
  if (formId === '08774265-3554-4150-9082-8fc21646e88c') {
    return (
      <Container maxWidth="sm">
        <Toast 
          open={openToast} 
          onClose={handleToastClose} 
          message={'Form successfully submitted, thank you!'} 
          severity={'success'}
        />
        <Toast 
          open={openFailedToast} 
          onClose={handleToastClose} 
          message={'Error while submitting form'} 
          severity={'error'}
        />
    
    <Grid container justify="center" alignItems="center">
      <Grid item height="100vh">
        <Paper style={{ padding: '20px', margin: 'auto' }}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={3}>
          <img 
            src="https://learnbehavioral.com/wp-content/uploads/2021/09/learn_logo.svg" 
            alt="LEARN Behavioral" 
            style={{ maxWidth: '100%', marginTop: '50px' }} 
          />
        </Box>
        <Typography variant="body1" gutterBottom>
          LEARN Behavioral is looking to provide you with the best possible care experience. Please fill out this 1-minute form so LEARN Behavioral can best understand your needs.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
          <TextField
            fullWidth
            margin="normal"
            label="Current email on file"
            variant="outlined"
            required
  
            name="emailOnFile"
            value={formData.emailOnFile}
            onChange={handleChange}
            helperText={errors.emailOnFile}
            error={Boolean(errors.emailOnFile)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="What is the best email to contact you?"
            variant="outlined"
  
            name="bestEmail"
            value={formData.bestEmail}
            onChange={handleChange}
            helperText={errors.bestEmail}
            error={Boolean(errors.bestEmail)}
  
            required
          />
          <FormControl component="fieldset" margin="normal" required>
            <FormLabel component="legend" style={{ color: 'rgb(246, 136, 31)'}}>LEARN Behavioral may pay statements by auto-charging your card on file</FormLabel>
            <RadioGroup row
              name="autoCharge"
              onChange={handleChange}
              helperText={errors.autoCharge}
              error={Boolean(errors.autoCharge)}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {errors.autoCharge && <FormHelperText style={{color: 'red'}} >Please select an option</FormHelperText>}
            {errors.autoCharge && <FormHelperText style={{color: 'black'}} >Please select an option</FormHelperText>}
          </FormControl>
          <Popup />
          <Button variant="contained" color="primary" type="submit" fullWidth style={{ marginTop: '10px', backgroundColor: 'rgb(24, 189, 91)' }} >
            Submit
          </Button>
        </Box>
        </Paper>
        </Grid>
      </Grid>
      </Container>
    );
  } else {
    return (
      <>Form not found</>
    )
  }
};

export default Form;
