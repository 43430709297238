import React from 'react';
import { makeStyles } from '@mui/styles'
import Modal from '@mui/material/Modal';
import LoginForm from './LoginForm'
import { Button } from '@mui/base';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 400,
    height: 250,
    backgroundColor: '#48494B',
    border: '2px solid #000',
    borderRadius: 10,
    boxShadow: 5,
  },
}));

export default function SimpleModal() {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
        <h1 className="login_center">
            Login
        </h1>
      <LoginForm />
    </div>
  );

  return (
    <div className="left">
      <button type="button" onClick={handleOpen} className="Header-button ">
        Login
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
