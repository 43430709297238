import axios from "axios";
import Cookies from "js-cookie";

const axiosClient = axios.create({
  baseURL: _getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  }
})

const customAxiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  }
})

axiosClient.interceptors.request.use(_setAuthTokens)

export async function get(url, params={ customURL: false, values: false }) {
  try {
    if (params && params.customURL) {
      const encodedParams = params.values ? new URLSearchParams(params).toString() : ''
      const res = await customAxiosClient.get(params.customURL + encodedParams)
      return _handleResponse(res)
    }
    const encodedParams = params && params.values ? new URLSearchParams(params).toString() : ''
    const res = await axiosClient.get(url + encodedParams)
    return _handleResponse(res)
  } catch (e) {
    return {}
  }
}

export async function put(url, body, params = {}) {

}

export async function post(url, body, params = { customURL: false }) {
  try {
    if (params && params.customURL) {
      const res = await customAxiosClient.post(params.customURL, body)
      return _handleResponse(res)
    }
    const res = await axiosClient.post(url, body)
    return _handleResponse(res)
  } catch (e) {
    return {}
  }
}

export async function patch(url, body, params = { customURL: false }) {
  try {
    if (params && params.customURL) {
      const res = await customAxiosClient.patch(params.customURL, body)
      return _handleResponse(res)
    }
    const res = await axiosClient.patch(url, body)
    return _handleResponse(res)
  } catch (e) {
    return {}
  }
}

export async function del(url, body, params = {}) {

}

function _handleResponse(response) {
  try {
    // if (response.statusText !== 200) {
    //   return false
    // }
    return response.data
  } catch {
    return {}
  }
}

function _setAuthTokens(config) {
  config.headers['X-CSRFTOKEN'] =  Cookies.get('csrftoken');
  return config
}

function _getBaseURL() {
  const baseURL =  process.env.IS_LOCAL
    ? 'https://dtia78buu6.execute-api.us-east-1.amazonaws.com'
    : 'https://dtia78buu6.execute-api.us-east-1.amazonaws.com'
  return baseURL
}
