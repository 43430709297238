// Popup.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Paper,
  TextField,
  Modal,
  Button,
  Box
} from '@mui/material';
import { Toast } from '../../../components';
import LearnPaymentOptions from '../../forms/LearnPaymentPlanFormComponents/LearnPaymentOptions'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 750,
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  maxHeight: '90vh',
  p: 2,
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Popup = (props) => {
  const { paymentPlans } = props

  const [selectedPlan, setSelectedPlan] = useState('');
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [openFailedToast, setOpenFailedToast] = useState(false);

  const [helperText, setHelperText] = useState({
    paymentPlan: '',
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const [formData, setFormData] = useState({
    clientId: '',
    paymentPlanTotal: '',
    address: {
      street: '',
      streetTwo: '',
      city: '',
      state: '',
      zip: ''
    }
  });

  const [errors, setErrors] = useState({
    emailOnFile: '',
    paperStatement: '',
    address: {
      street: '',
      streetTwo: '',
      city: '',
      state: '',
      zip: ''
    }
  });

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
    setOpenFailedToast(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes('address')) {
      const addressField = name.split('.')[1];
      setFormData({
        ...formData,
        address: {
          ...formData.address,
          [addressField]: value || ''
        }
      });
      setErrors({
        ...errors,
        address: {
          ...errors.address,
          [addressField]: ''
        }
      });
    } else {
      setFormData({ ...formData, [name]: value || '' });
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newErrors = {};
  };

  return (
    <div>
        <Toast 
          open={openToast} 
          onClose={handleToastClose} 
          message={'Form successfully submitted, thank you!'} 
          severity={'success'}
        />
        <Toast 
          open={openFailedToast} 
          onClose={handleToastClose} 
          message={'Error while submitting form'} 
          severity={'error'}
        />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleOpen}
        style={{ margin: '10px', backgroundColor: '#d4967d', fontWeight: 'bold' }}
      >
        Create New Client
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Paper style={{ padding: '15px', margin: 'auto', maxWidth: '500px' }}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={2}>
              <img 
                src="https://learnbehavioral.com/wp-content/uploads/2021/09/learn_logo.svg" 
                alt="LEARN Behavioral" 
                style={{ maxWidth: '100%' }} 
              />
            </Box>
            <Box component="form" onSubmit={handleSubmit2} noValidate autoComplete="off">
              <TextField
                fullWidth
                size="small"
                margin="normal"
                label="Client Id"
                variant="outlined"
                required
                name="clientId"
                value={formData.clientId}
                onChange={handleChange}
                helperText={errors.clientId}
                error={Boolean(errors.clientId)}
              />
              <TextField
                fullWidth
                margin="normal"
                size="small"
                label="Payment Plan Total"
                variant="outlined"
                required
                name="paymentPlanTotal"
                value={formData.paymentPlanTotal}
                onChange={handleChange}
                helperText={errors.address ? errors.paymentPlanTotal : ''}
                error={Boolean(errors.paymentPlanTotal)}
              />
              <LearnPaymentOptions 
                existingPaymentPlan={[]} 
                invoiceDetails={{
                  total: 100000
                }} 
                handleSubmit={() => ''}
                selectedPlan={selectedPlan}
                handlePlanChange={handlePlanChange}
                paymentPlans={paymentPlans}
                errors={errors}
                helperText={helperText}
              />
            </Box>
          </Paper>
        </Box>
      </Modal>
    </div>
  );
};

export default Popup;
