import moment from 'moment';
import { removeUser, resetStore } from '../store/authentication'
import { store } from '../store'

const isLoggedIn = async (auth) => {
  if (!auth || !auth.session || ! auth.session.sessionId) return false
  const date = moment(auth.session.expiryDateTime)
  if (date.isBefore(moment())) {
    store.dispatch(removeUser())
    store.dispatch(resetStore())
    return false
  }
  return true
}

export default isLoggedIn
