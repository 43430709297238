import { post } from './utils'

export const submitFormData = (formData) => {
  return async dispatch => {
    const response = await post(`/api/forms`, JSON.stringify(formData));
    if (response && response.id) {
      return true
    }
    return false
  };
};
