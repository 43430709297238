import Cookies from "js-cookie";
import { get, post } from './utils'

const SET_USER = "sommWiki/authentication/SET_USER";
const REMOVE_USER = "sommWiki/authentication/REMOVE_USER";

export const setUser = (user) => {
  return {
    type: SET_USER,
    user
  }
}

export const removeUser = () => {
  return {
    type: REMOVE_USER
  }
}

export const login = (email, password) => {
  return async dispatch => {
    const response = await post(`/api/authentication/login`, JSON.stringify({ email, password }));
    if (response.user) {
      await dispatch(setUser(response.user));
      return true
    }
    return false
  };
};

const RESET_STORE = 'APP/RESET'
export const resetStore = () => ({
  type: RESET_STORE,
});

export const logout = () => async dispatch => {

  const XSRFTOKEN = await fetch('/api/auth/getToken')
  const token = (await XSRFTOKEN.json())

  const res = await fetch('/api/auth/logout', {
    method: "delete",
    headers: {
      'csrf-token':token.XSRFTOKEN
    },
  });
  if (res.ok) {
    dispatch(removeUser());
    dispatch(resetStore());
  }
}


function loadUser() {
  const authToken = Cookies.get("token");
  if (authToken) {
    try {
      const payload = authToken.split(".")[1];

      const decodedPayload = atob(payload);

      const payloadObj = JSON.parse(decodedPayload);
      const { data } = payloadObj;
      return data;
    } catch (e) {
      Cookies.remove("token");
    }
  }
  return {};
}

export default function reducer(state=loadUser(), action) {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case REMOVE_USER:
      return {};
    default:
      return state;
  }
}
