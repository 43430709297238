import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import ExpandableTable from './PaymentPlanTable'
import PaymentPlanSearchBar from './PaymentPlanSearchBar'
import isLoggedIn from '../../../utils/isLoggedIn';
import { Toast } from '../..'
import { Grid } from '@mui/material';
import { get, patch } from '../../../store/utils'
import CreatePaymentPlanPopup from './CreatePaymentPlanPopup'

export default function PrimaryPaymentPlanPage() {
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [openToast, setOpenToast] = useState(false);
  const [openFailedToast, setOpenFailedToast] = useState(false);
  const navigate = useNavigate({ replace: true });
  const authentication = useSelector(state => state.authentication);

  useEffect(async () => {
    if (!await isLoggedIn(authentication)) {
      navigate('/login')
    }
    (async () => {
      const res = await get(`/api/objects/form_EbKHGPlK`)
      setPaymentPlans(res.object)
    })()
  }, [])

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
    setOpenFailedToast(false);
  };

  const handleSave = (currentRow, rowUpdates) => {
    const updateBody = {
      paymentPlanId: currentRow.id,
      paymentPlanTotal: '',
      selectedPlanDetails: '',
    }
    const totalAsStripeNumber = Number(rowUpdates.totalAmount.replace("$", "")) * 100

    if (currentRow.paymentPlanTotal != totalAsStripeNumber) {
      updateBody.paymentPlanTotal = totalAsStripeNumber
    }
    if (rowUpdates.totalMonths != currentRow.selectedPlanDetails.maxMonths) {
      updateBody.selectedPlanDetails = {
        id: -1,
        maxMonths: Number(rowUpdates.totalMonths),
        percent: Number(rowUpdates.monthlyPercent),
      }
    }
    if (!updateBody.paymentPlanTotal && !updateBody.selectedPlanDetails) {
      setOpenToast(true);
      return
    }
    (async () => {
      try {
        const res = await patch(``, updateBody,
        { customURL: `https://5pvfpf93rd.execute-api.us-east-1.amazonaws.com/api/learn/paymentplans/update`})
        if (res && res.id) {
          setOpenToast(true);
        } else {
          setOpenFailedToast(true)
        }
      } catch (e) {
        setOpenFailedToast(true);
      }
    })()
  };

  return (
    <Grid item height="90vh">
      <CreatePaymentPlanPopup paymentPlans={paymentPlans} />
      <Toast 
        open={openToast} 
        onClose={handleToastClose} 
        message={'Payment plan successfully updated'} 
        severity={'success'}
      />
      <Toast 
        open={openFailedToast} 
        onClose={handleToastClose} 
        message={'Error updating payment plan'} 
        severity={'error'}
      />
      <PaymentPlanSearchBar 
        setRows={setRows}
        page={page}
      />
      <ExpandableTable
        rows={rows}
        page={page}
        setPage={setPage}
        onSave={handleSave}
      />
    </ Grid>
  )
}
