import React, { useEffect } from 'react';

import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Pagination
} from '@mui/material';
import ExpandableTableRow from './PaymentPlanTableRow'

export default function ExpandableTable(props) {
  const {
    rows,
    page,
    setPage,
    onSave,
  } = props

  useEffect(() => {
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Payment Plan Id</TableCell>
              <TableCell>Client Id</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Payments Made</TableCell>
              <TableCell>Remaining Owed</TableCell>
              <TableCell>Is Active</TableCell>
              <TableCell>Is Past Due</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.map((row) => (
              <ExpandableTableRow key={row.name} row={row} onSave={onSave} />
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={10}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
      </TableContainer>
    </>
  );
}
