module.exports = {
    COMPANY: {
        SET_COMPANY: 'COMPANY/SET_COMPANY',
        SET_COMPANIES: 'COMPANY/SET_COMPANIES',
    },
    BOTS: {
        SET_BOT_ACTIVATION: 'BOTS/SET_BOT_ACTIVATION',
        SET_SINGLE_BOT: 'BOTS/SET_SINGLE_BOT',
        SET_BOTS: 'BOTS/SET_BOTS',
    }
}
