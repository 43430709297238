import React from 'react';
import { FormHelperText, Select, MenuItem, FormControl, Button, Box } from '@mui/material';

const LearnPaymentOptions = (props) => {
  const {
    existingPaymentPlan,
    invoiceDetails,
  } = props
  const hasExistingPaymentPlan = existingPaymentPlan && existingPaymentPlan.length && existingPaymentPlan.find((p) => !p.isFullyPaid)
  const invoiceIsPaid = invoiceDetails && invoiceDetails.status === 'paid'
  const invoiceAmountLessThen300 = (invoiceDetails && invoiceDetails.total ? invoiceDetails.total / 100 : 0) < 300

  if (hasExistingPaymentPlan || invoiceIsPaid || invoiceAmountLessThen300) {
    return <></>
  }
  const {
    handleSubmit,
    selectedPlan,
    handlePlanChange,
    paymentPlans,
    errors,
    helperText,
  } = props

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <FormControl component="fieldset" margin="normal" required fullWidth>
        <Select
          value={selectedPlan}
          onChange={handlePlanChange}
          displayEmpty
          renderValue={(selected) => selected ? selected : 'Select a payment plan'}
        >
          <MenuItem disabled value="">
            Select a payment plan
          </MenuItem>
          {paymentPlans && paymentPlans.map((plan) => (
            <MenuItem key={plan.id} value={plan.displayText}>
              {plan.displayText}
            </MenuItem>
          ))}
        </Select>
        {errors.paymentPlan && <FormHelperText style={{color: 'red'}} >Please select a payment plan option</FormHelperText>}
        {helperText.paymentPlan && <FormHelperText style={{color: 'black'}} >* {helperText.paymentPlan}</FormHelperText>}
      </FormControl>
      <Button 
        variant="contained" 
        color="primary" 
        type="submit" 
        fullWidth 
        style={{ marginTop: '10px', backgroundColor: '#d4967d' }}
      >
        Submit
      </Button>
    </Box>
  )
}

export default LearnPaymentOptions
