import React from 'react';
import LearnForm from './LearnPaymentInfoForm'
import PaymentPlanForm from './LearnPaymentPlanForm'
import { useParams } from 'react-router-dom';

const FormChooser = () => {
  const { formId } = useParams();
  if (formId === '08774265-3554-4150-9082-8fc21646e88c') {
    return <LearnForm />
  } else if (formId === 'form_EbKHGPlK') {
    return <PaymentPlanForm />
  } else {
    return <>Form not found</>
  }
}

export default FormChooser
