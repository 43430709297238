import React from 'react';
import { 
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@mui/material/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CUSTOM_COLORS_MAPPING = {
  success: 'rgb(24, 189, 91)',
  // info: palette.secondary[500],
  error: 'red',
  // warning: palette.warning[500],
}

const Toast = ({ open, onClose, message, severity, autoHideDuration=4000 }) => {
  // severity can be success, info, warning, error

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} style={{ backgroundColor:CUSTOM_COLORS_MAPPING[severity]}}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
