import React from 'react';
import { Typography, Box } from '@mui/material';

const LearnPaymentPlanHeader = (props) => {
  const {
    existingPaymentPlan,
    invoiceDetails,
  } = props
  const hasExistingPaymentPlan = existingPaymentPlan && existingPaymentPlan.length && existingPaymentPlan.find((p) => !p.isFullyPaid)
  const invoiceIsPaid = invoiceDetails && invoiceDetails.status === 'paid'
  const hasInvoiceLoaded = Boolean(invoiceDetails)
  let text;

  if (hasExistingPaymentPlan) {
    text = ''
  } else if (invoiceIsPaid) {
    text = 'Learn Behavioral offers interest-free payment plans to all of our clients. ' 
      + 'Please reach out to us by selecting the “contact us” link below to discuss your options.'
  } else if (hasInvoiceLoaded) {
    if ((invoiceDetails.total ? invoiceDetails.total / 100 : 0) < 300) {
      text = 'Learn Behavioral offers interest-free payment plans to all of our clients.'
      +' Please reach out to us by selecting the “contact us” link below to discuss your options. '
    } else {
      text = 'LEARN Behavioral offers interest-free payment plans to all of our clients. ' 
        + 'Please see below options for your current invoice balance total. ' 
        + 'If you need additional support, please click the “contact us” link below.'
    }
  } else {
    return <></>
  }

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={3}>
        <img 
          src="https://learnbehavioral.com/wp-content/uploads/2021/09/learn_logo.svg" 
          alt="LEARN Behavioral" 
          style={{ maxWidth: '100%', marginTop: '50px' }} 
        />
      </Box>
      <Typography variant="body1" gutterBottom>
        {text}
      </Typography>
    </>
  )
}

export default LearnPaymentPlanHeader
