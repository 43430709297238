import React, { useState, useEffect } from 'react'
import { Paper, TextField, Button, Typography, Grid, Box } from '@material-ui/core';
import { post } from '../../store/utils'
import Toast from '../Toast'

const ContactForm = () => {
  useEffect(()=>{
  },[])

  const searchParams = new URLSearchParams(window.location.search);
  const clientId = searchParams.get('client');
  const [openToast, setOpenToast] = useState(false);
  const [openFailedToast, setOpenFailedToast] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
    setOpenFailedToast(false)
  };

  const [formData, setFormData] = useState({
    contactFullName: '',
    email: '',
    phone: '',
    invoiceId: '',
    patientFullName: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    contactFullName: '',
    email: '',
    phone: '',
    patientFullName: '',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    // Clear the related error message when the user makes changes
    setErrors({ ...errors, [name]: '' });
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};

    if (formData.contactFullName.trim() === '') {
      newErrors.contactFullName = 'Contact name is required.';
    }
    if (formData.email.trim() === '') {
      newErrors.email = 'Email on file is required.';
    } else if (!validateEmail(formData.email.trim())) {
      newErrors.email = 'Please provide a valid email.';
    }
    if (formData.message.trim() === '') {
      newErrors.message = 'Message is required.';
    }
    if (formData.patientFullName.trim() === '') {
      newErrors.patientFullName = 'Patient full name is required to best assist you.';
    }
    formData.website = 'LEARN'

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const body = {
        company: 'LEARN_BILLING',
        subject: 'Patient Responsibility Contact Form',
        html:  "<div>Hello,</div>" 
          + "<p>Please see below for a next contact from submission from the PR Invoicing Bot:</p>" 
          + '<h3>Form Data:</h3>'
          + '',
      }

      body.html += `<div>Contact Full Name: ${formData.contactFullName}</div>`
      body.html += `<div>Patient Full Name: ${formData.patientFullName}</div>`
      body.html += `<div>Email on File: ${formData.email}</div>`
      body.html += `<div>Phone Number: ${formData.phone || 'Not entered'}</div>`
      body.html += `<div>Invoice Id: ${formData.invoiceId || 'Not entered'}</div>`
      body.html += `<p>Message: ${formData.message}</p>`
      try {
        const res = await post('/api/contact', body)
        if (res && res.success) {
          setOpenToast(true);
        } else {
          setOpenFailedToast(true)
        }
      } catch (e) {
        setOpenFailedToast(true)
      }
    }
  };

  return (
    <Grid container maxWidth="sm" justifyContent="center" alignItems="center" style={{ height: '90vh' }}>
      <Toast 
        open={openToast} 
        onClose={handleToastClose} 
        message={'Contact successfully submitted'} 
        severity={'success'}
      />
      <Toast 
        open={openFailedToast} 
        onClose={handleToastClose} 
        message={'There was an issue submitting the contact form'} 
        severity={'error'}
      />
     <Paper elevation={3} style={{ padding: '20px', maxWidth: '500px', margin: 'auto' }}>
        <form>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={3}>
            <img 
              src="https://learnbehavioral.com/wp-content/uploads/2021/09/learn_logo.svg" 
              alt="LEARN Behavioral" 
              style={{ maxWidth: '100%', marginTop: '50px' }} 
            />
          </Box>
          
          <Typography variant="h5" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="p" gutterBottom>
            Please fill out this form to let us know how we can help you! You can also email <b>billing.inquiry@learnbehavioral.com</b> or reach out to our Billing Team at <b>(747) 400-2032</b>.
          </Typography>
          <TextField
            label="Contact Full Name"
            name="contactFullName"
            value={formData.contactFullName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.contactFullName)}
            helperText={errors.contactFullName}
          />
          <TextField
            label="Patient Full Name"
            name="patientFullName"
            value={formData.patientFullName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.patientFullName)}
            helperText={errors.patientFullName}
          />
          <TextField
            label="Email on file"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
          <TextField
            label="Invoice Id (Optional)"
            name="invoiceId"
            value={formData.invoiceId}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone Number (Optional)"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            multiline
            rows={6}
            error={Boolean(errors.message)}
            helperText={errors.message}
          />
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth
            style={{ display: 'inline-block', marginTop: '10px', marginRight: '50px',  backgroundColor: 'rgb(24, 189, 91)' }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </form>
      </Paper> 
    </Grid>
  );
}

export default ContactForm
