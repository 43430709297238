import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Paper, Container, Typography, TextField, FormHelperText, Grid, Select, MenuItem,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Box } from '@mui/material';
import { Toast } from '../../components'
import { get, post } from '../../store/utils'
import LearnPaymentPlanHeader from './LearnPaymentPlanFormComponents/LearnPaymentPlanHeader'
import LearnPaymentPlanSubHeaderText from './LearnPaymentPlanFormComponents/LearnPaymentPlanSubHeaderText'
import LearnPaymentOptions from './LearnPaymentPlanFormComponents/LearnPaymentOptions'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


const PaymentPlanForm = () => {
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [existingPaymentPlan, setExistingPaymentPlan] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [errors, setErrors] = useState({
    paymentPlan: '',
  });
  const [helperText, setHelperText] = useState({
    paymentPlan: '',
  });


  const query = useQuery();
  const queryParamInvoiceId = query.get('invoice');
  const queryParamclientId = query.get('client');
  const { formId } = useParams();

  useEffect(() => {
    Promise.all([
      (async () => {
        if (formId) {
          const res = await get(`/api/objects/${formId}`)
          setPaymentPlans(res.object)
        }
      })(),
      (async () => {
        if (queryParamInvoiceId) {
          const res = await get(``, { customURL: `https://5pvfpf93rd.execute-api.us-east-1.amazonaws.com` 
          + `/api/learn/invoices/${queryParamInvoiceId}`})
          setInvoiceDetails(res.invoice)
        }
      })(),
      (async () => {
        if (queryParamclientId) {
          const res = await get(``, { customURL: `https://5pvfpf93rd.execute-api.us-east-1.amazonaws.com` 
            + `/api/learn/paymentplans/${queryParamclientId}`})
          setExistingPaymentPlan(res.paymentPlans || [])
          // const res = await get(``, { customURL: `https://5pvfpf93rd.execute-api.us-east-1.amazonaws.com` 
          // + `/api/learn/invoices/${queryParamInvoiceId}`})
        }
      })()
    ])
  }, [formId, queryParamInvoiceId])

    const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
    setErrors({ paymentPlan: '' })
    const selectedPlanDetails = paymentPlans.find(plan => plan.displayText === event.target.value);
    const nonRoundedNumner = (Number(invoiceDetails.total / 100) / Number(selectedPlanDetails.maxMonths)) || 0
    const monthlyAmount = Math.round((nonRoundedNumner + Number.EPSILON) * 100) / 100
    setHelperText({
      paymentPlan: `Selecting this plan will result in a monthly payment of $${monthlyAmount} for ${'10'} months`
    })
  };

  const [openToast, setOpenToast] = useState(false);
  const [openFailedToast, setOpenFailedToast] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
    setOpenFailedToast(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!selectedPlan) {
      const newErrors = {
        ...errors
      }
      newErrors.paymentPlan = 'Please select a payment plan'
      setErrors(newErrors)
      return
    }
    const selectedPlanDetails = paymentPlans.find(plan => plan.displayText === selectedPlan);

    const formPostSuccess = (async () => {
      await post('', {
        clientId: queryParamclientId, 
        invoiceId: queryParamInvoiceId,
        selectedPlanDetails,
        invoiceDetails,
      }, { customURL: `https://5pvfpf93rd.execute-api.us-east-1.amazonaws.com` 
      + `/api/learn/paymentplans/create`})
    })()
    if (formPostSuccess) {
      setOpenToast(true);
    } else {
      openFailedToast(true)
    }
  };
  const showHeader = !existingPaymentPlan.length
  return (
    <Container maxWidth="sm">
      <Toast 
        open={openToast} 
        onClose={handleToastClose} 
        message={'Payment plan successfully submitted, thank you!'} 
        severity={'success'}
      />
      <Toast 
        open={openFailedToast} 
        onClose={handleToastClose} 
        message={'Error while submitting form'} 
        severity={'error'}
      />
      <Grid container justify="center" alignItems="center">
        <Grid item height="90vh">
          <Paper style={{ padding: '20px', margin: 'auto' }}>
            <LearnPaymentPlanHeader  existingPaymentPlan={existingPaymentPlan} invoiceDetails={invoiceDetails} />
            <LearnPaymentPlanSubHeaderText existingPaymentPlan={existingPaymentPlan} invoiceDetails={invoiceDetails} />
            <LearnPaymentOptions
              existingPaymentPlan={existingPaymentPlan} 
              invoiceDetails={invoiceDetails} 
              handleSubmit={handleSubmit}
              selectedPlan={selectedPlan}
              handlePlanChange={handlePlanChange}
              paymentPlans={paymentPlans}
              errors={errors}
              helperText={helperText}
            />
            <div style={{ marginTop: '10px' }}>
                If you have any questions, please <a href="/contact/learn">contact us.</ a>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentPlanForm;
