// Popup.js
import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Paper,
  Typography,
  TextField,
  FormHelperText,
  Link,
  Modal,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box
} from '@mui/material';
import { submitFormData } from '../../store/forms';
import { useDispatch } from 'react-redux';
import { Toast } from '../../components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 750,
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  maxHeight: '90vh',
  p: 2,
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Popup = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [openFailedToast, setOpenFailedToast] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { formId } = useParams();
  const query = useQuery();
  const queryParamEmail = query.get('email');

  const [formData, setFormData] = useState({
    emailOnFile: queryParamEmail || 'example@example.com',
    paperStatement: 'yes',
    address: {
      street: '',
      streetTwo: '',
      city: '',
      state: '',
      zip: ''
    }
  });

  const [errors, setErrors] = useState({
    emailOnFile: '',
    paperStatement: '',
    address: {
      street: '',
      streetTwo: '',
      city: '',
      state: '',
      zip: ''
    }
  });

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  const validateZipCode = (zip) => {
    const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    return zipRegex.test(zip);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
    setOpenFailedToast(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes('address')) {
      const addressField = name.split('.')[1];
      setFormData({
        ...formData,
        address: {
          ...formData.address,
          [addressField]: value || ''
        }
      });
      setErrors({
        ...errors,
        address: {
          ...errors.address,
          [addressField]: ''
        }
      });
    } else {
      setFormData({ ...formData, [name]: value || '' });
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newErrors = {};

    if (formData.emailOnFile.trim() === '') {
      newErrors.emailOnFile = 'Email is required.';
    } else if (!validateEmail(formData.emailOnFile.trim())) {
      newErrors.emailOnFile = 'Please provide a valid email.';
    }

    if (!formData.paperStatement) {
      newErrors.paperStatement = 'Selection required';
    }
    if (formData.address.street.trim() === '') {
      newErrors.address = { ...newErrors.address, street: 'Street address is required.' };
    }
    if (formData.address.city.trim() === '') {
      newErrors.address = { ...newErrors.address, city: 'City is required.' };
    }
    if (formData.address.state.trim() === '') {
      newErrors.address = { ...newErrors.address, state: 'State is required.' };
    }
    if (formData.address.zip.trim() === '') {
      newErrors.address = { ...newErrors.address, zip: 'ZIP code is required.' };
    } else if (!validateZipCode(formData.address.zip.trim())) {
      newErrors.address = { ...newErrors.address, zip: 'Please provide a valid ZIP code.' };
    }

    if (Object.keys(newErrors).length > 0 || (newErrors.address  && Object.keys(newErrors.address ).length > 0)) {
      setErrors(newErrors);
      return
    } else {
      const formPostSuccess = await dispatch(submitFormData({
        formId,
        formData,
        companyId: 'LEARN'
      }));
      if (formPostSuccess) {
        setOpenToast(true);
      } else {
        setOpenFailedToast(true);
      }
    }
  };

  return (
    <div>
        <Toast 
          open={openToast} 
          onClose={handleToastClose} 
          message={'Form successfully submitted, thank you!'} 
          severity={'success'}
        />
        <Toast 
          open={openFailedToast} 
          onClose={handleToastClose} 
          message={'Error while submitting form'} 
          severity={'error'}
        />
      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
        Opting into auto-charge card-on-file constitutes an agreement with LEARN Behavioral's card-on-file terms and conditions. If you would like to opt into paper billing please fill out{' '}
        <Link href="#" onClick={handleOpen} underline="always">
          this form
        </Link>
        .
      </Typography>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Paper style={{ padding: '15px', margin: 'auto', maxWidth: '500px' }}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={2}>
              <img 
                src="https://learnbehavioral.com/wp-content/uploads/2021/09/learn_logo.svg" 
                alt="LEARN Behavioral" 
                style={{ maxWidth: '100%' }} 
              />
            </Box>
            <Typography variant="body1" gutterBottom>
              LEARN Behavioral is looking to provide you with the best possible care experience. Please fill out this 1-minute form so LEARN Behavioral can best serve your paper billing request.
            </Typography>
            <Box component="form" onSubmit={handleSubmit2} noValidate autoComplete="off">
              <TextField
                fullWidth
                size="small"
                margin="normal"
                label="Current email on file"
                variant="outlined"
                required
                name="emailOnFile"
                value={formData.emailOnFile}
                onChange={handleChange}
                helperText={errors.emailOnFile}
                error={Boolean(errors.emailOnFile)}
              />
              <TextField
                fullWidth
                margin="normal"
                size="small"
                label="Street Address"
                variant="outlined"
                required
                name="address.street"
                value={formData.address.street}
                onChange={handleChange}
                helperText={errors.address ? errors.address.street : ''}
                error={Boolean(errors.address.street)}
              />
              <TextField
                fullWidth
                margin="normal"
                size="small"
                label="Street Address Line Two"
                variant="outlined"
                required
                name="address.streetTwo"
                value={formData.address.streetTwo}
                onChange={handleChange}
                helperText={errors.address ? errors.address.streetTwo : ''}
                error={Boolean(errors.address.street)}
              />
              <TextField
                fullWidth
                margin="normal"
                size="small"
                label="City"
                variant="outlined"
                required
                name="address.city"
                value={formData.address.city}
                onChange={handleChange}
                helperText={errors.address ? errors.address.city : ''}
                error={Boolean(errors.address.city)}
              />
              <TextField
                fullWidth
                margin="normal"
                size="small"
                label="State"
                variant="outlined"
                required
                name="address.state"
                value={formData.address.state}
                onChange={handleChange}
                helperText={errors.address ? errors.address.state : ''}
                error={Boolean(errors.address.state)}
              />
              <TextField
                fullWidth
                size="small"
                margin="normal"
                label="ZIP Code"
                variant="outlined"
                required
                name="address.zip"
                value={formData.address.zip}
                onChange={handleChange}
                helperText={errors.address ? errors.address.zip : ''}
                error={Boolean(errors.address.zip)}
              />
              <FormControl component="fieldset" margin="normal" required>
                <FormLabel component="legend" style={{ color: 'rgb(246, 136, 31)'}}>
                  I would like LEARN Behavioral to send me paper invoices instead of e-statements.
                </FormLabel>
                <RadioGroup 
                  row
                  name="paperStatement"
                  value={formData.paperStatement} // Set the default value here
                  onChange={handleChange}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                {errors.paperStatement && <FormHelperText style={{color: 'red'}} >Please select an option</FormHelperText>}
              </FormControl>
              <Button variant="contained" color="primary" type="submit" fullWidth style={{ marginTop: '10px', backgroundColor: 'rgb(24, 189, 91)' }} >
                Submit
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </div>
  );
};

export default Popup;
