import { COMPANY } from './constants'
import { get, put, post, del } from './utils'
const { SET_COMPANIES, SET_COMPANY } = COMPANY;

const setCompany = (company) => ({ type: SET_COMPANY, company })
const setCompanies = (companies) => ({ type: SET_COMPANIES, companies })

export const getCompany = ({ id }) => async dispatch => {
  const company = await get(`companies/${id}`)
  dispatch(setCompany(company))
}

export const getCompanies = (params = {}) => async dispatch => {
  const companies = await get('companies', params)
  dispatch(setCompanies(companies))
}


export default function reducer(state={ curCompany: [], searchResults: [] }, action) {
    let newState;
    switch (action.type) {
      case SET_COMPANY:
        newState = {...state}
        newState.curCompany = action.company
        return newState
      case SET_COMPANIES:
          newState = {...state}
          newState.searchResults = action.companies
          return newState
      default:
        return state;
    }
}
