import React, { useState, useEffect } from 'react'
import { Paper, TextField, Button, Typography, Grid, Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { login } from '../store/authentication'
import { Toast } from '.'

const LoginForm = () => {
  const dispatch = useDispatch()
  useEffect(()=>{
  },[])

  const navigate = useNavigate({ replace: true });

  const redirectToSignUp = () => {
    // if (clientId) {
    //   navigate(`/weddingpro?client=${clientId}`)
    // } else {
    //   navigate('/weddingpro')
    // }
  }

  const [formData, setFormData] = useState({
    password: '',
    email: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const [openToast, setOpenToast] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
    if (formData.email.trim() === '') {
      newErrors.email = 'Email is required.';
    } else if (!validateEmail(formData.email.trim())) {
      newErrors.email = 'Please provide a valid email.';
    }

    if (formData.password.trim() === '') {
      newErrors.password = 'Password is required.'
    }


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const loginSuccess = await dispatch(login(formData.email, formData.password))

      if (loginSuccess) {
        navigate('/bots')
      } else {
        setOpenToast(true);
      }
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Toast 
        open={openToast} 
        onClose={handleToastClose} 
        message={'There was an issue logging in. Please check your email and password then try again.'} 
        severity={'error'}
      />


     <Paper elevation={3} style={{ padding: '20px', maxWidth: '300px', margin: 'auto' }}>
        <form>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/async_lambda_logo.png`}
            alt="Example"
            sx={{
              width: '100%',
              height: 'auto',
            }}
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
          <TextField
            label="Password"
            name="password"
            value={formData.password}
            helperText={errors.password}
            error={Boolean(errors.password)}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="password"
          />
          <Grid container spacing={2} justifyContent="space-between" style={{ marginTop: '10px' }}>

            <Button 
              type="submit" 
              variant="contained" 
              style={{ display: 'block', marginTop: '10px',  backgroundColor: '#d4967d', fontWeight: '600'}}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}

export default LoginForm
