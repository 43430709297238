import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import {
  HomePage, Header, Footer, LoginForm, BotSummaryPage, LEARNForm, FormChooser, ContactLearn, LearnPaymentPlanPage
} from './components'


import './App.css';

function App() {
  useEffect(()=>{
  },[])

  return (
      <BrowserRouter>
        <Container sx={{ paddingBottom: '60px' }}>
          <Routes>
            <Route path="/learn/paymentplans"  element={<LearnPaymentPlanPage />}/>
            <Route path="/contact/learn"  element={<ContactLearn />}/>
            <Route path="/forms/:formId"  element={<FormChooser />}/>
            <Route path="/login"  element={<LoginForm />}/>
            <Route path="/bots"  element={<BotSummaryPage />}/>
            <Route path="*"  element={<BotSummaryPage />}/>
          </Routes>
        </Container>
      </BrowserRouter>
);
}

export default App;
